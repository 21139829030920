import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_CommonInfo = _resolveComponent("CommonInfo")
  const _component_CpuUsage = _resolveComponent("CpuUsage")
  const _component_MemoryUsage = _resolveComponent("MemoryUsage")
  const _component_DiskUsage = _resolveComponent("DiskUsage")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CommonInfo),
    _createVNode(_component_CpuUsage, { class: "p-mt-2" }),
    _createVNode(_component_MemoryUsage, { class: "p-mt-2" }),
    _createVNode(_component_DiskUsage, { class: "p-mt-2" })
  ], 64))
}